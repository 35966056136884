/**
 * My Elements Helper
 */

const Elements = {
    createButton({ textContent='', onClick=null}) {
        const button = document.createElement('button');
        button.textContent = textContent;
        if(onClick) button.addEventListener('click', () => { onClick(); })
        return button;
    },
    
    createHeader({ size = 1, textContent = '' }) {
    if (size < 1 || size > 6) return null;
    const header = document.createElement(`h${size}`);
    header.textContent = textContent;
    return header;
    },
};

export default Elements;