import './sass/main.scss';
import Elements from './lib/Elements';

const appContainer = document.getElementById('appContainer');


const header = Elements.createHeader({
    textContent: "Let there be rock"
})

const button = Elements.createButton({
    textContent: "Click me!",
    onClick: () => { appContainer.appendChild(header); }
});

appContainer.appendChild(button);